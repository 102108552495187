<template>
    <div class="user-settings-menu">
        <ul class="user-settings-list">
            <template
                v-for="(item, index) in slice.items.filter((item) =>
                    user && isCompany ? item.visibleFor === 'companies' : item.visibleFor === 'freelances',
                )"
            >
                <li v-if="isFilledKeyText(item.linkText) && isFilledKeyText(item.link)" :key="`link-${index}`" class="menu-item">
                    <a :href="item.link">{{ item.linkText }}</a>
                </li>
            </template>
            <li class="logout">
                <form action="/logout" method="post">
                    <input name="_csrf" type="hidden" :value="xsrfToken" />

                    <input class="cta-item" type="submit" :value="slice.primary.logOutText" />
                </form>
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
    import type {PropType} from 'vue';
    import {ref, onMounted} from 'vue';
    import {storeToRefs} from 'pinia';
    import {useCookie} from '#imports';
    import type {UserSettingsSliceSlice} from '@prismicio-types';
    import {useTypeGuards} from '@navbar-unlogged/composables';
    import {useUser} from '@navbar-unlogged/store/user.store';

    defineProps({
        slice: {
            type: Object as PropType<UserSettingsSliceSlice>,
            required: true,
        },
    });

    const {user, isCompany} = storeToRefs(useUser());

    const {isFilledKeyText} = useTypeGuards();

    const xsrfToken = ref();

    onMounted(() => {
        xsrfToken.value = useCookie('XSRF-TOKEN').value;
    });
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    @use '@navbar-unlogged/scss/variables.scss' as vars;

    ul {
        padding: 0;
        margin: 0;
    }

    .user-settings-menu {
        .user-settings-list {
            display: flex;
            flex-direction: column;
            margin: 0;
            list-style: none;

            li:first-child {
                border-top-left-radius: var(--joy-core-radius-4);
                border-top-right-radius: var(--joy-core-radius-4);
            }

            li:last-child {
                border-bottom-left-radius: var(--joy-core-radius-4);
                border-bottom-right-radius: var(--joy-core-radius-4);
            }

            @include mq.screen_md {
                padding: var(--joy-core-spacing-8) 0;
                gap: var(--joy-core-spacing-6);
            }
        }

        .logout {
            margin-bottom: 8px;

            .cta-item {
                cursor: pointer;
            }

            @include mq.screen_md {
                order: 999;
                .cta-item {
                    margin-top: 8px;
                    width: 100%;
                }
            }
        }

        .menu-item {
            a {
                padding-top: 20px;
                padding-bottom: 20px;
                background-color: transparent !important;
                text-decoration: none;
                color: var(--joy-color-neutral-50);
                width: 100%;
                display: inline-block;

                @include mq.screen_md {
                    font-size: var(--joy-font-size-primary-400);
                    color: var(--joy-color-neutral-50);
                    padding: 0;
                }
            }

            &:hover {
                background-color: var(--joy-color-secondary-10);
            }
        }

        .cta-item {
            margin: 5px 20px;

            @include vars.button-secondary-blue;
            @include vars.button-small;
            background-color: transparent;
            width: calc(100% - 40px);
            transition: background-color var(--joy-transition-duration-default) var(--joy-transition-timing-function);

            &:hover {
                background-color: var(--joy-color-secondary-10);
            }

            @include mq.screen_md {
                margin: 0px;
            }
        }

        :deep(.settings-button) {
            margin: 0 20px;

            > button {
                background-color: transparent !important;
                border: none;
                position: relative;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;

                &:focus {
                    box-shadow: none !important;
                }
            }

            @include mq.screen_md {
                list-style: none;
                display: flex;
                flex-direction: column;
                gap: 24px;
                padding: 32px 0px;
                margin: 0;
            }

            &:after {
                background: none repeat scroll 0 0 transparent;
                background: var(--joy-color-secondary-30);
                content: '';
                display: block;
                left: 50%;
                position: absolute;
                bottom: -13px;
                width: 0;
                transition: width var(--joy-transition-duration-default) var(--joy-transition-timing-function),
                    left var(--joy-transition-duration-default) var(--joy-transition-timing-function);
                width: 0;
                height: 4px;
                border-radius: 4px;
            }

            &:hover {
                &:after {
                    width: 100%;
                    left: 0;
                }
            }
        }
    }
</style>
