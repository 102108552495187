<template>
    <section v-if="user && user.connected" class="user-settings" data-testid="user-settings">
        <div class="mobile-content">
            <UserSettingsControls :slice="slice" />
        </div>
        <Dropdown id="user-settings" class="desktop-content settings-button" menu-class="settings-dropdown">
            <template #header>
                <VJoyIcon name="settings-outline" size="small" />
            </template>
            <template #content>
                <UserSettingsControls :slice="slice" />
            </template>
        </Dropdown>
    </section>
</template>

<script setup lang="ts">
    import {storeToRefs} from 'pinia';
    import {VJoyIcon} from '@maltjoy/core-vue';
    import {getSliceComponentProps} from '@prismicio/vue';
    import type {UserSettingsSliceSlice} from '@prismicio-types';
    import Dropdown from '@navbar-unlogged/components/Dropdown.vue';
    import UserSettingsControls from '@navbar-unlogged/components/UserSettingsControls.vue';
    import {useUser} from '@navbar-unlogged/store/user.store';

    const {user} = storeToRefs(useUser());

    defineProps(getSliceComponentProps<UserSettingsSliceSlice>());
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;

    .mobile-content {
        display: none;
        @include mq.screen_md {
            display: block;
        }
    }

    .desktop-content {
        @include mq.screen_md {
            display: none;
        }
    }

    .user-settings {
        .settings-button {
            height: 100%;

            :deep(> button) {
                height: 100%;
                width: 65px;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &:after {
                    background: none repeat scroll 0 0 transparent;
                    background: var(--joy-color-secondary-30);
                    content: '';
                    display: block;
                    left: 50%;
                    position: absolute;
                    bottom: -13px;
                    width: 0;
                    transition: width var(--joy-transition-duration-default) var(--joy-transition-timing-function),
                        left var(--joy-transition-duration-default) var(--joy-transition-timing-function);
                    width: 0;
                    height: 4px;
                    border-radius: 4px;
                }

                &:hover {
                    &:after {
                        width: 80%;
                        left: 10%;
                    }
                }
            }
        }

        :deep(.settings-dropdown) {
            transform: translateX(calc(40px - 100%));
            top: calc(100% + 40px);
            border-radius: var(--joy-core-radius-4);
            min-width: 300px;
        }
    }
</style>
